export const technicalSkillsConfig = [
  "HTML5",
  "CSS3 (+SCSS, BEM)",
  "JavaScript ES6+",
  "React",
  "TypeScript",
  "Redux",
  "Firebase",
  "Next.js",
  "Material UI",
  "Tailwind CSS",
];
